import React from 'react';
import { Helmet } from 'react-helmet';

import { baseUrlFront } from '../config';

import { GenerateString } from './GenerateRandomString';

export default (props) => {
  return (
    <div>
      <Helmet>
        {/*<title>{`iVoox Indonesia - ${props.data.title}`}</title>*/}
        <title>{`${props.data.title ? `${props.data.title} | ` : ''}iVoox Indonesia`}</title>
        <meta name='title' content={`${props.data.title} | Ivoox Indonesia`} />
        <meta
          name='description'
          content={
            props.data.description
              ? props.data.description
              : props.data.content
                  .replace(/(<([^>]+)>)/gi, ' ')
                  .replace(/&nbsp;/g, ' ')
                  .slice(0, 280)
          }
        />
        <meta name='keywords' content={props.data.keyword} />
        <meta name='keywords' content={props.data.keyword} />

        <meta name='og:type' content='website' />
        <meta name='og:url' content={`${baseUrlFront}/${props.data.url}`} />
        <meta name='og:title' content={props.data.title} />
        <meta
          name='og:description'
          content={
            props.data.description
              ? props.data.description
              : props.data.content
                  .replace(/(<([^>]+)>)/gi, ' ')
                  .replace(/&nbsp;/g, ' ')
                  .slice(0, 280)
          }
        />
        <meta name='og:image' content={props.data.mediaable && props.data.mediaable.media ? props.data.mediaable.media.url : ``} />
        {/* <meta name="og:image" content={`${props.data.mediaable} `&&` ${props.data.mediaable.media} `?` ${props.data.mediaable.media.url}${GenerateString+Math.random().toString(36).substring(1)} `:` ```} /> */}

        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:url' content={`${baseUrlFront}/news/${props.data.url}`} />
        <meta name='twitter:title' content={props.data.title} />
        <meta
          name='twitter:description'
          content={
            props.data.description
              ? props.data.description
              : props.data.content
                  .replace(/(<([^>]+)>)/gi, ' ')
                  .replace(/&nbsp;/g, ' ')
                  .slice(0, 280)
          }
        />
        <meta
          name='twitter:image'
          content={
            `${props.data.mediaable}` && `${props.data.mediaable.media} `
              ? `${props.data.mediaable.media.url}${GenerateString + Math.random().toString(36).substring(1)}`
              : ` ```
          }
        />
        <meta name='google-site-verification' content='kKYqdt3i-S2IAoZg1k_uaU7j5VpSzUaet1Tgmmhg0Pw' />
        <link rel='canonical' href={`${baseUrlFront}/${props.data.url}`} />
      </Helmet>
    </div>
  );
};
