import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Api from '../services/api';

// const menusManual = [
//   {
//     id: 1,
//     title: 'Berita Utama',
//     slug: 'berita-utama',
//     parent: null,
//   },
//   // {
//   //   id: 16,
//   //   title: 'ASO',
//   //   slug: 'aso',
//   //   parent: null,
//   // },
//   {
//     id: 2,
//     title: 'Perbankan',
//     slug: 'perbankan',
//     parent: null,
//   },
//   {
//     id: 3,
//     title: 'Pasar Modal',
//     slug: 'pasar-modal',
//     parent: null,
//   },
//   {
//     id: 4,
//     title: 'NonBank',
//     slug: 'non-bank',
//     parent: null,
//   },
//   {
//     id: 5,
//     title: 'Literasi',
//     slug: 'literasi',
//     parent: null,
//   },
//   {
//     id: 6,
//     title: 'Ekonomi',
//     slug: 'ekonomi',
//     parent: null,
//   },
//   // {
//   //   id: 7,
//   //   title: 'Ramadan',
//   //   slug: 'ramadan',
//   //   parent: null,
//   // },
//   {
//     id: 8,
//     title: 'Investasi',
//     slug: 'investasi',
//     parent: null,
//   },
//   {
//     id: 10,
//     title: 'Ebook',
//     slug: 'ebook',
//     parent: null,
//   },
//   // {
//   //     id: 9,
//   //     title: 'Netizen',
//   //     slug: 'netizen-journalist',
//   //     parent: null
//   // },
//   {
//     id: 12,
//     title: 'Media',
//     slug: 'media',
//     parent: [
//       {
//         id: 13,
//         title: 'Foto',
//         slug: 'foto',
//       },
//       {
//         id: 14,
//         title: 'Infografik',
//         slug: 'infografik',
//       },
//       {
//         id: 15,
//         title: 'Video',
//         slug: 'video',
//       },
//     ],
//   },
// ];

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputSearch: '',
      submittedSearch: false,
      datasNavigation: [],
      loadingNavigation: false
    };

    this.onSubmitSearch = this.onSubmitSearch.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
  }

  // componentDidMount() {
  //   this.fetchNavigation();
  // }

  onChangeSearch(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  onSubmitSearch(e) {
    e.preventDefault();

    this.setState({ submittedSearch: true });
    const { inputSearch } = this.state;
    this.props.history.push(`/search?keyword=${inputSearch}`);
  }

  fetchNavigation() {
    this.setState({ loadingNavigation: true }, () => {
      // const params = `page=1&limit=10&keyword=`;
      // const route = `/category/index?${params}`;
      const route = '/category/getCategoriesByOrder';
      Api.get(route, null)
        .then((response) => {
          this.setState({ menusManual: response.data });
          this.setState({ loadingNavigation: false });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loadingNavigation: false });
        });
    });
  }

  checkPageHome() {
    if (window.location.pathname == '/') {
      window.location.reload();
    }
  }

  smartCheckNav(slug) {
    var newUri = `/category/${slug}`;
    var oldUri = window.location.pathname;
    if (newUri === oldUri) window.location.reload();
    else this.props.history.push(newUri);
  }

  render() {
    const { datasNavigation, loadingNavigation, inputSearch, submittedSearch } = this.state;
    const menusManual = this.props.menusManual;
    return (
      <header className='nav nav-ivoox' id='main-header-ivoox'>
        <div className='nav__holder nav--sticky'>
          <div className='container relative'>
            <div className='flex-parent'>
              <div className='flex-child'>
                <button className='nav-icon-toggle hidden-desktop' id='nav-icon-toggle' aria-label='Open side menu'>
                  <span className='nav-icon-toggle__box'>
                    <span className='nav-icon-toggle__inner' />
                  </span>
                </button>
              </div>

              <nav className='flex-child nav__wrap d-none d-lg-block'>
                <ul className='nav__menu ivoox_nav_menu'>
                  <li>
                    <Link onClick={this.checkPageHome} to={`/`}>
                      Home
                    </Link>
                  </li>
                  {menusManual?.length > 0 ? (
                    menusManual.map((nav, i) => (
                      <li
                        className={`${Number(nav.is_focus) ? 'nav_highlight' : ''} ${nav.parent?.length > 0 ? 'nav__dropdown' : ''}`}
                        style={{
                          display: Number(nav.is_hide) ? 'none' : 'inline-block'
                        }}
                        key={i}
                      >
                        {nav.parent?.length > 0 ? (
                          <>
                            <a href={`/category/${nav.slug}`}>{nav.name}</a>
                            <ul className='nav__dropdown-menu'>
                              {nav.parent.map((navParent, iParent) => (
                                <li key={iParent}>
                                  <Link to={`/category/${navParent.slug}`}>{navParent.name?.toUpperCase()}</Link>
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <Link
                            // to={`/category/${nav.slug}`}
                            onClick={() => this.smartCheckNav(nav.slug)}
                            className='nav__dropdown'
                          >
                            {nav.name}
                          </Link>
                        )}
                      </li>
                    ))
                  ) : (
                    <></>
                  )}
                  {/* <li>
                    <Link to={`/category/k-vox`}>Kvox</Link>
                  </li> */}
                </ul>
              </nav>

              <Link onClick={this.checkPageHome} to={`/`} className='logo logo-mobile d-lg-none'>
                <img
                  className='logo__img'
                  src={'/assets/img/logo.png'}
                  alt='logo ivoox'
                  // src={require('../../assets/img/logo-dark.png')}
                />
              </Link>

              <div className='flex-child'>
                <div className='nav__right'>
                  <div className='nav__right-item nav__search'>
                    <a href='javascript:void(0);' className='nav__search-trigger' id='nav__search-trigger'>
                      <i className='ui-search nav__search-trigger-icon' />
                    </a>
                    <div className='nav__search-box' id='nav__search-box'>
                      <form className='nav__search-form' name='formSearch' onSubmit={this.onSubmitSearch}>
                        <input
                          type='text'
                          placeholder='Search an article'
                          className='nav__search-input'
                          id='inputSearch'
                          name='inputSearch'
                          value={inputSearch}
                          onChange={this.onChangeSearch}
                        />
                        <button type='submit' className='search-button btn btn-lg btn-color btn-button'>
                          <i className='ui-search nav__search-icon' />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Navigation;
